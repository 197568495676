import React, { useState, useEffect } from "react";
import "./Delegate.css";
import Twitter from "../assets/partnerstwitter.svg";
import Favorite from "../assets/favorite.svg";
import alertsData from "./alertdata";

const Delegate = () => {
  const [visibleAlerts, setVisibleAlerts] = useState(alertsData.slice(0, 6));
  const [currentIndex, setCurrentIndex] = useState(6);

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = (currentIndex + 6) % alertsData.length;
      setVisibleAlerts(alertsData.slice(nextIndex, nextIndex + 6));
      setCurrentIndex(nextIndex);
    }, 5000);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  const handleCardClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <div id="partners" className="main-delegate">
        <div className="main22-delegate">
          <div className="delegate-main-head"></div>
          <div>
            <div className="lets-text xs:hidden sm:hidden md:block  mb-[5%] lg:text-5xl md:text-4xl sm:text-2xl xs:text-xl">
              Client &nbsp;<span className="talk-text"> ACK's</span>
            </div>

            <div className="xs:hidden sm:hidden md:block lg:block ">
              <div className="grid grid-cols-3 gap-20">
                {visibleAlerts.map((alert, index) => (
                  <div
                    key={index}
                    className={`alert-card ${
                      index >= currentIndex && index < currentIndex + 6
                        ? "fade-in"
                        : "fade-out"
                    }`}
                    onClick={() => handleCardClick(alert.link)}
                  >
                    <div className="front">
                      <div className="alert2001 border-none px-5 py-5 rounded-xl h-[290px]">
                        <div className="flex justify-between mb-4">
                          <div className="flex space-x-2">
                            <img
                              src={alert.profileImage.src}
                              alt="Person Image"
                              width={alert.profileImage.width}
                              height={alert.profileImage.height}
                              style={{
                                borderRadius: `${alert.profileImage.borderRadius}px`,
                              }}
                            />
                            <div className="">
                              <div className="Jack-Zampolin ">{alert.name}</div>
                              <span className="Jackk_1234">
                                {alert.username}
                              </span>
                            </div>
                          </div>
                          <img src={Twitter} alt="Twitter" />
                        </div>

                        <div className="mt-2">
                          <div className="twitter-text h-[140px]">
                            {alert.message}
                          </div>
                          <div className="flex justify-between mt-5">
                            <div className="flex">
                              <img src={Favorite} alt="Favorite-icon" />
                              <p className="date-text">{alert.likes}</p>
                            </div>
                            <div className="date-text">{alert.date}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="back absolute top-[50%] w-full cursor-pointer">
                      <a
                        href={alert.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block w-[366px] p-2 text-rgb(225,225,225) font-bold text-center cursor-pointer overflow-hidden"
                      >
                        {alert.link}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Delegate;
